/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    em: "em"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-2-rock-paper-scissors"
  }, "Day 2: Rock Paper Scissors"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/2"
  }, "https://adventofcode.com/2022/day/2")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_02.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "We’re playing a ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Rock_paper_scissors"
  }, "Rock, Paper, Scissors"), " tournament with the elves.\nThe input represents an encryped strategy guide."), "\n", React.createElement(_components.p, null, "Each line has 2 letters seperated by a space."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The first letter is ", React.createElement(_components.code, null, "A"), ", ", React.createElement(_components.code, null, "B"), ", or ", React.createElement(_components.code, null, "C"), "."), "\n", React.createElement(_components.li, null, "The second letter is ", React.createElement(_components.code, null, "X"), ", ", React.createElement(_components.code, null, "Y"), ", or ", React.createElement(_components.code, null, "Z"), "."), "\n"), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "A Y\nB X\nC Z\n")), "\n", React.createElement(_components.p, null, "Each round is worth some points, all scores get summed up, and whoever has the highest total at the end of the tournament wins."), "\n", React.createElement(_components.p, null, "Your score for a single round is the sum of the score for the ", React.createElement(_components.em, null, "shape"), " you played, and the score for the ", React.createElement(_components.em, null, "outcome"), " of the round."), "\n", React.createElement(_components.p, null, "Shape scores:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Rock: 1"), "\n", React.createElement(_components.li, null, "Paper: 2"), "\n", React.createElement(_components.li, null, "Scissors: 3"), "\n"), "\n", React.createElement(_components.p, null, "Outcome scores:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Loss: 0"), "\n", React.createElement(_components.li, null, "Draw: 3"), "\n", React.createElement(_components.li, null, "Win: 6"), "\n"), "\n", React.createElement(_components.p, null, "The first letter in the input is what your opponent is going to play.\n", React.createElement(_components.code, null, "A"), "for Rock, ", React.createElement(_components.code, null, "B"), " for Paper, and ", React.createElement(_components.code, null, "C"), " for Scissors."), "\n", React.createElement(_components.p, null, "Before the elf can tell you what the second letter means, they leave."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "Winning every round would be suspicious, so whatever that second letter is, it has to be important."), "\n", React.createElement(_components.p, null, "You assume the second letter is the shape you should play.\n", React.createElement(_components.code, null, "X"), "for Rock, ", React.createElement(_components.code, null, "Y"), " for Paper, and ", React.createElement(_components.code, null, "Z"), " for Scissors."), "\n", React.createElement(_components.p, null, "If “Rock”, “Paper”, and “Scissors” have positions in a list."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To win, move 1 position to the right (and wrap around from “Scissors” to “Rock”!)"), "\n", React.createElement(_components.li, null, "To draw, keep the same position"), "\n", React.createElement(_components.li, null, "To lose, move 1 position to the left (and wrap around from “Rock” to “Scissors”!)"), "\n"), "\n", React.createElement(_components.p, null, "So I translated both ", React.createElement(_components.code, null, "A"), ", ", React.createElement(_components.code, null, "B"), ", ", React.createElement(_components.code, null, "C"), ", and ", React.createElement(_components.code, null, "X"), ", ", React.createElement(_components.code, null, "Y"), ", ", React.createElement(_components.code, null, "Z"), " to ", React.createElement(_components.code, null, "0"), ", ", React.createElement(_components.code, null, "1"), ", and ", React.createElement(_components.code, null, "2"), " respectively.\nThankfully, both ABC and XYZ are sequences where the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/ASCII"
  }, "ASCII"), " value of a letter increases by 1 each step."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The value for the ", React.createElement(_components.em, null, "shape"), " the opponent plays is known."), "\n", React.createElement(_components.li, null, "The value for the ", React.createElement(_components.em, null, "shape"), " I play is known."), "\n", React.createElement(_components.li, null, "To calculate the score for this round, we need to know the ", React.createElement(_components.em, null, "outcome"), " of the round."), "\n"), "\n", React.createElement(_components.p, null, "With those two pieces of information a game of Rock, Paper, Scissors can be expressed as the following equation:"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "outcome = my_shape - opponent_shape + 1 (mod 3)")), "\n", React.createElement(_components.p, null, "This expresses ", React.createElement(_components.code, null, "outcome"), " as a number from 0 to 2:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "0 for loss"), "\n", React.createElement(_components.li, null, "1 for draw"), "\n", React.createElement(_components.li, null, "2 for win"), "\n"), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "mod 3"), " handles the wrapping around logic.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "pub fn part_1() -> String {\n    let input = std::fs::read_to_string(\"src/day02.txt\").unwrap();\n\n    input\n        .lines()\n        // map every line to the score for that round\n        .map(|line| {\n            // transform A B C and X Y Z to 0 1 2 respectively by using their ASCII order\n            let bytes = line.as_bytes();\n            let left = (bytes[0] - b'A') as i8;\n            let right = (bytes[2] - b'X') as i8;\n\n            // 0 for rock, 1 for paper, 2 for scissors\n            // 0 for loss, 1 for draw, 2 for win\n            let opponent_shape = left;\n            let my_shape = right;\n            let outcome = (my_shape - opponent_shape + 1).rem_euclid(3);\n\n            let shape_score = my_shape + 1;\n            let outcome_score = 3 * outcome;\n            (shape_score + outcome_score) as u32\n        })\n        .sum::<u32>()\n        .to_string()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The elf returns and tells you that the second letter in the input is the desired ", React.createElement(_components.em, null, "outcome"), ".\n", React.createElement(_components.code, null, "X"), " for loss, ", React.createElement(_components.code, null, "Y"), " for draw, ", React.createElement(_components.code, null, "Z"), " for win."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The value for the ", React.createElement(_components.em, null, "shape"), " the opponent plays is known."), "\n", React.createElement(_components.li, null, "The value for the ", React.createElement(_components.em, null, "outcome"), " of the round is known."), "\n", React.createElement(_components.li, null, "To calculate the score for this round, we need to know the ", React.createElement(_components.em, null, "shape"), " we need to play."), "\n"), "\n", React.createElement(_components.p, null, "We rearrange the equation from part1 to solve for ", React.createElement(_components.code, null, "my_shape"), " instead of ", React.createElement(_components.code, null, "outcome"), ":"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "my_shape = opponent_shape - 1 + outcome (mod 3)")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "mod 3"), " handles the wrapping around logic.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "pub fn part_2() -> String {\n    let input = std::fs::read_to_string(\"src/day02.txt\").unwrap();\n\n    input\n        .lines()\n        // map every line to the score for that round\n        .map(|line| {\n            // transform A B C and X Y Z to 0 1 2 respectively by using their ASCII order\n            let bytes = line.as_bytes();\n            let left = (bytes[0] - b'A') as i8;\n            let right = (bytes[2] - b'X') as i8;\n\n            // 0 for rock, 1 for paper, 2 for scissors\n            // 0 for loss, 1 for draw, 2 for win\n            let opponent_shape = left;\n            let outcome = right;\n            let my_shape = (opponent_shape - 1 + outcome).rem_euclid(3);\n\n            let shape_score = my_shape + 1;\n            let outcome_score = 3 * outcome;\n            (shape_score + outcome_score) as u32\n        })\n        .sum::<u32>()\n        .to_string()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
